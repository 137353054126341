import React from "react";
import { useTranslation } from "react-i18next"

import { Helmet } from "react-helmet";

export default function Projects() {
    const [t] = useTranslation('global');

    return (
        <div className="Template-left">
            <Helmet>
                <title>Blauker - {t("nav.op2")}</title>
                <meta name="description" content="All the Games and Apps created by Blauker." />
            </Helmet>
            <div className="container">
                <div id="my projects">
                    <h1><b>{t("apps.title.t1")} <d className="blue-text">{t("apps.title.t2")}</d>{t("apps.title.t3")}</b></h1>

                    {t("apps.text.line1")} <br />
                    {t("apps.text.line2")}
                    <ul>
                        <li><b className="blue-text">{t("apps.text.l1")}</b>{t("apps.text.t1")}</li>
                        <li><b className="blue-text">{t("apps.text.l2")}</b>{t("apps.text.t2")}</li>
                        <li><b className="blue-text">{t("apps.text.l3")}</b>{t("apps.text.t3")}</li>
                        <li><b className="blue-text">{t("apps.text.l4")}</b>{t("apps.text.t4")}</li>
                        <li><b className="blue-text">{t("apps.text.l5")}</b>{t("apps.text.t5")}</li>
                        <li><b className="blue-text">{t("apps.text.l6")}</b>{t("apps.text.t6")}</li>
                    </ul>
                </div>

                <br /> <br />

                <div id="projects">
                    <h3> <b>{t("apps.finished.text")}</b></h3>
                    <a href="/7ds-cc-calculator">
                        <div className="app-box-f text-center">
                            <img className="mini-portrait" src="images/Apps/7DSCCCalculator.png" alt="" /> <br />
                            <b className="blue-text"> 7DS CC Calculator</b>: [2021] <br />
                            <i>{t("apps.finished.calculator.extra")}</i> <br />
                            {t("apps.finished.calculator.desc1")} <a className="blue-text-l" href="https://7dsgc.netmarble.com/es#introduce">{t("apps.finished.calculator.link")}</a>{t("apps.finished.calculator.desc2")} <br />
                        </div>
                    </a>
                    <a href="https://tof-database.blauker.com/">
                        <div className="app-box text-center justify-content-center">
                            <img className="mini-portrait" src="images/Apps/ToF-Database.png" alt="" /><br />
                            <b className="blue-text"> www.tof-database.com</b>: [2022]<br />
                            {t("apps.finished.tof-db.desc")}
                        </div>
                    </a>
                    <a href="https://github.com/Blauker/Image-Feather">
                        <div className="app-box text-center justify-content-center">
                            <img className="mini-portrait" src="images/Apps/ImageFeather.png" alt="" /><br />
                            <b className="blue-text"> Image Feather</b>: [2022]<br />
                            {t("apps.finished.imgf.desc")}
                        </div>
                    </a>
                </div>

                <br /><br />

                <div id="old-projects">
                    <h3> <b>{t("apps.old.text")}</b></h3>
                    <a href="https://haisse-dev.itch.io/naeniam-pre-alpha-1-2">
                        <div className="app-box-f text-center">
                            <img className="mini-portrait" src="images/Apps/Naeniam.png" alt="" /><br/>
                            <b className="blue-text"> Naeniam</b>: [2019] <br />
                            {t("apps.old.naeniam.desc")}<br />
                        </div>
                    </a>
                    <a href="https://lh5.googleusercontent.com/Dm_FnN6d4GiFYXeqtEstIozLOdyJT5U6uuf-b6vAsdDTPT1rhXIX2H8eYq3yDtkftd9EiuQh6FLXUJEY6WHyLKo=w16383">
                        <div className="app-box text-center">
                            <img className="mini-portrait" src="images/Apps/ProtNES.png" alt="" /> <br/>
                            <b className="blue-text"> Protocolo: NES</b>: [2020]<br />
                            {t("apps.old.protocolo.desc1")} <b>{t("apps.old.protocolo.bold")}</b> {t("apps.old.protocolo.desc2")} <br />
                        </div>
                    </a>
                </div>
            </div>
        </div >
    );
}
