import React from "react";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";

export default function Home() {
    const [t] = useTranslation('global');

    return (
        <div className="Home">
            <Helmet>
                <title>Blauker - {t("nav.op1")}</title>
                <meta name="description" content="This website is the portfolio of Blauker. Created by Blauker." />
            </Helmet>
            <div className="container">
                <div className="d-flex justify-content-center">
                    <img className="logo-responsive" src="images/Blauker_logo_dark_b.png" alt="blauker_logo" />
                </div>
                <hr />
                <div className="text-center">
                    {t("home.text.line1")} <b className="blue-text">{t("home.text.line2")}</b> {t("home.text.line3")} <br />
                    {t("home.text.br")} <b className="blue-text">{t("home.text.line4")}</b> {t("home.text.line5")} <b className="blue-text">{t("home.text.line6")}</b> {t("home.text.line7")} <br />
                </div>

                <br /><br />

                <div className="text-center">
                    <h2>
                        <b>{t("home.social.t1")}<a className="blue-text-l" href="mailto:blauker.contact@gmail.com">{t("home.social.link")}</a>{t("home.social.t2")}</b>
                    </h2>
                </div>
                <a href="https://twitter.com/BlaukerDev" target="_blank" rel="noopener noreferrer">
                    <img className="icon" src="images/Icons/twitterr.png" alt="Twitter" /></a>
                <a href="https://es.linkedin.com/in/jorge-ballesteros-cuerda-60134414a" target="_blank" rel="noopener noreferrer">
                    <img className="icon" src="images/Icons/linkedin.png" alt="LinkedIn" /></a>
                <a href="https://www.reddit.com/user/Giyuu_Blauker/" target="_blank" rel="noopener noreferrer">
                    <img className="icon" src="images/Icons/reddit.png" alt="Reddit" /></a>
                <a href="https://github.com/Blauker" target="_blank" rel="noopener noreferrer">
                    <img className="icon" src="images/Icons/github.png" alt="GitHub" /></a>
                <a href="https://play.google.com/store/apps/dev?id=7706527853211406030&hl=es&gl=US" target="_blank" rel="noopener noreferrer">
                    <img className="icon" src="images/Icons/googlec.png" alt="google play" /></a>

                <br /><br /><br />

                <div className="text-center">
                    <h2>
                        <b>{t("home.apps.t1")}<a className="blue-text-l" href="/projects">{t("home.apps.link")}</a>{t("home.apps.t2")}</b>
                    </h2>
                </div>
                <a href="/7ds-cc-calculator">
                    <img className="portrait" src="images/Apps/7DSCCCalculator.png" alt="" /></a>
                <a href="https://tof-database.blauker.com/" target="_blank" rel="noopener noreferrer">
                    <img className="portrait" src="images/Apps/ToF-Database.png" alt="" /></a>
                <a href="/projects">
                    <img className="portrait" src="images/Apps/ImageFeather.png" alt="" /></a>
                <a href="/projects">
                    <img className="portrait" src="images/Apps/ProtNES.png" alt="" /></a>

                <br /><br /><br />

                <div className="text-center">
                    <h2>
                        <b>{t("home.info.t1")}<a className="blue-text-l" href="/about">{t("home.info.link")}</a>{t("home.info.t2")}</b>
                    </h2>
                </div>
                <div className="text-center">
                    <b>
                        {t("home.info.line1")}<br />
                        {t("home.info.line2")}<br />
                        {t("home.info.line3")}<br />
                        {t("home.info.line4")}<br />
                        {t("home.info.line5")}<br />
                        {t("home.info.line6")}
                    </b>
                </div>

            </div>
        </div>
    );
}
